import {ChangeDetectionStrategy, Component, DestroyRef, inject} from '@angular/core';
import {
  DialogConfirmEvent,
  MsaDialogAction,
  MsaDialogComponent
} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EmailVerifyDto, PersonRestService} from '../../core/api/generated/msa-person-data';
import {catchError, never, tap} from 'rxjs';
import shajs from 'sha.js';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {HttpErrorResponse} from '@angular/common/http';
import {createValidationError} from '@shared/shared-module/utils/validator.utils';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MsaTextInputComponent} from '@shared/shared-module/components/msa-text-input/msa-text-input.component';
import {MsaArrowLinkComponent} from '@shared/shared-module/components/msa-arrow-link/msa-arrow-link.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaDialogComponent, SafeTranslatePipe, ReactiveFormsModule, MsaTextInputComponent, MsaArrowLinkComponent],
  selector: 'msa-email-verify-dialog',
  standalone: true,
  templateUrl: './email-verify-dialog.component.html'
})
export class EmailVerifyDialogComponent {
  public data: {
    emailVerifyDto: EmailVerifyDto;
    verificationHash: string;
  } = inject(MAT_DIALOG_DATA);
  protected readonly title: TranslationString = 'i18n.person-data.dialogs.email-verify.title';

  private personRestService: PersonRestService = inject(PersonRestService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private errorMessageHandler: ErrorMessageHandler = inject(ErrorMessageHandler);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private dialogRef: MatDialogRef<MsaDialogAction> = inject(MatDialogRef);

  emailVerifyForm = new FormGroup({
    verificationCode: new FormControl('', [Validators.required, Validators.maxLength(6)])
  });

  verifyCode($event: DialogConfirmEvent): void {
    const inputCode = this.emailVerifyForm.value.verificationCode ?? '';
    // Hash code with SHA-256
    const hash = shajs('sha256').update(inputCode).digest('hex');
    if (hash === this.data.verificationHash) {
      $event.resolve(inputCode);
    } else {
      this.emailVerifyForm.controls.verificationCode.setErrors({
        invalidCode: createValidationError('i18n.person-data.dialogs.email-verify.wrongVerificationCode')
      });
      this.emailVerifyForm.controls.verificationCode.updateValueAndValidity();
      $event.reject();
    }
  }

  resendVerificationEmail() {
    // Send a new verification code
    this.personRestService
      .verifyPersonEmails(this.data.emailVerifyDto)
      .pipe(
        tap(() => {
          this.snackbarService.openSnackbar({
            text: 'i18n.person-data.dialogs.email-verify.resentVerificationCode',
            type: MessageType.Success
          });
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse && error.status === 429) {
            this.snackbarService.openSnackbar({
              text: 'i18n.person-data.dialogs.email-verify.waitBeforeSendingNewCode',
              type: MessageType.Info
            });
            return never();
          }
          return this.errorMessageHandler.logAndIgnore(error);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  onCancel() {
    this.dialogRef.close();
  }
}
