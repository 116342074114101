import {Component} from '@angular/core';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MilitaryHistoryComponent} from '../../components/military-history/military-history.component';
import {MilitaryCurrentComponent} from '../../components/military-current/military-current.component';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {PersonalStatusComponent} from '../../components/personal-status/personal-status.component';
import {FeatureFlagDirective} from '@shared/shared-module/directives/feature-flag.directive';

@Component({
  selector: 'app-military-career',
  standalone: true,
  imports: [
    MilitaryCurrentComponent,
    MilitaryHistoryComponent,
    SafeTranslatePipe,
    MilitaryHistoryComponent,
    MilitaryCurrentComponent,
    MsaContentNoticeComponent,
    PersonalStatusComponent,
    FeatureFlagDirective
  ],
  templateUrl: './military-career.component.html'
})
export class MilitaryCareerComponent {}
