<msa-dialog
  [confirmButtonText]="'i18n.common.save'"
  [disabled]="!contactForm.valid"
  [footNote]="'i18n.person-data.dialogs.phone-edit.foot-note'"
  [title]="'i18n.person-data.dialogs.emergency-contacts.title'"
  (confirmEvent)="updateEmergencyContacts($event)"
>
  <form [formGroup]="contactForm">
    <div class="msa-text" formArrayName="contacts">
      @for (contact of contactForm.controls.contacts.controls; track contact; let idx = $index) {
        <div class="tw-border-t tw-border-black-light-4 last-of-type:tw-border-b" [formGroupName]="idx">
          <div class="tw-my-18 tw-hidden tw-flex-row tw-items-center tw-justify-between d:tw-flex">
            <h3 class="msa-text-h3">
              {{ "i18n.person-data.dialogs.emergency-contacts.emergency-contact" | safeTranslate }} {{ idx + 1 }}
            </h3>
            <button
              class="tw-grid tw-h-[54px] tw-w-[58px] tw-place-content-center tw-text-black-light-5 max-d:tw-hidden"
              (click)="removeContact(idx)"
            >
              <mat-icon class="!tw-size-18" svgIcon="delete" />
            </button>
          </div>

          <div class="tw-grid tw-grid-cols-1 tw-gap-x-30 max-d:tw-my-15 d:tw-mb-18 d:tw-me-[88px] d:tw-grid-cols-2">
            <div class="tw-flex tw-flex-col tw-gap-y-9 max-d:tw-mb-9 d:tw-gap-y-12">
              <!-- Name -->
              <div class="">
                <msa-text-input
                  id="name-{{ idx }}"
                  [label]="'i18n.person-data.dialogs.emergency-contacts.name' | safeTranslate"
                  formControlName="name"
                />
              </div>
              <!-- Relationship -->
              <div class="tw-flex tw-flex-col tw-gap-3 d:tw-gap-y-6">
                <label class="tw-mb-3 d:tw-mb-6" for="personalRelation-{{ idx }}">
                  {{ "i18n.person-data.dialogs.emergency-contacts.relation" | safeTranslate }}
                </label>
                <msa-autocomplete
                  [filterControl]="$any(contact.get('personalRelation'))"
                  [options]="personalRelationshipCodeList()!"
                />
              </div>
              <!-- Address 1 -->
              <div class="">
                <msa-text-input
                  id="address1-{{ idx }}"
                  [label]="'i18n.person-data.dialogs.emergency-contacts.street' | safeTranslate"
                  formControlName="address1"
                  maxlength="55"
                />
              </div>
              <!-- Address 2 -->
              <div class="">
                <msa-text-input
                  id="address2-{{ idx }}"
                  [label]="'i18n.person-data.dialogs.emergency-contacts.address-addition' | safeTranslate"
                  formControlName="address2"
                  maxlength="55"
                />
              </div>
              <!-- Zip Code / City -->
              <div class="tw-flex tw-flex-col tw-gap-3 d:tw-gap-y-6">
                <div>
                  <label for="zipCode-{{ idx }}">
                    {{ "i18n.person-data.dialogs.emergency-contacts.plz" | safeTranslate }}
                  </label>
                  /
                  <label for="city-{{ idx }}">
                    {{ "i18n.person-data.dialogs.emergency-contacts.city" | safeTranslate }}
                  </label>
                </div>
                <div class="tw-col-span-1 tw-grid tw-grid-cols-3 tw-gap-x-9 d:tw-gap-x-18">
                  <div class="tw-col-span-1">
                    <msa-text-input id="zipCode-{{ idx }}" formControlName="zipCode" maxlength="12" />
                  </div>
                  <div class="tw-col-span-2">
                    <msa-text-input id="city-{{ idx }}" formControlName="city" maxlength="30" />
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-y-9 d:tw-gap-y-12">
              <!-- Email -->
              <div class="tw-col-span-1">
                <msa-text-input
                  id="email-{{ idx }}"
                  [label]="'i18n.person-data.dialogs.emergency-contacts.email' | safeTranslate"
                  formControlName="email"
                />
              </div>
              <!-- Phone Mobile -->
              <div class="tw-col-span-1">
                <msa-text-input
                  id="phoneMobile-{{ idx }}"
                  [label]="('i18n.person-data.dialogs.emergency-contacts.mobile' | safeTranslate) + ' (*)'"
                  ngxMask="+00 00 000 00 00*"
                  formControlName="phoneMobile"
                />
              </div>
              <!-- Phone Home -->
              <div class="tw-col-span-1">
                <msa-text-input
                  id="phoneNumber-{{ idx }}"
                  [label]="('i18n.person-data.dialogs.emergency-contacts.phone' | safeTranslate) + ' (*)'"
                  ngxMask="+00 00 000 00 00*"
                  formControlName="phoneNumber"
                />
              </div>
            </div>

            <button
              class="tw-mt-18 tw-flex tw-items-center tw-gap-x-12 tw-justify-self-end tw-text-black-light-5 d:tw-hidden"
              (click)="removeContact(idx)"
              data-cy="removeContact"
            >
              <span>
                {{ "i18n.person-data.dialogs.emergency-contacts.delete-contact" | safeTranslate }}
              </span>
              <mat-icon class="!tw-size-18" svgIcon="delete" />
            </button>
          </div>
        </div>
      } @empty {
        <p class="tw-border-y tw-border-black-light-4 tw-py-18">
          {{ "i18n.person-data.dialogs.emergency-contacts.no-contacts" | safeTranslate }}
        </p>
      }
    </div>
    <div class="tw-mt-18">
      <msa-button
        [class.tw-hidden]="contactForm.controls.contacts.length >= 2"
        (click)="addContact()"
        widthClass="tw-w-full d:tw-w-auto"
        color="secondary"
        data-cy="addContact"
      >
        <mat-icon class="!tw-size-18 d:!tw-size-21" svgIcon="plus" />
        <span>
          {{ "i18n.person-data.dialogs.emergency-contacts.add-button" | safeTranslate }}
        </span>
      </msa-button>
    </div>
    <div
      class="tw-mt-30 tw-flex tw-flex-row tw-gap-x-9 d:tw-mt-60"
      [class.!tw-hidden]="contactForm.controls.contacts.length === 0"
    >
      <msa-checkbox id="emergency-contacts-agreement" formControlName="agreement" />
      <p>
        {{ "i18n.person-data.dialogs.emergency-contacts.agreement" | safeTranslate }}
      </p>
    </div>
  </form>
</msa-dialog>
