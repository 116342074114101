<msa-dialog
  [title]="'i18n.person-data.dialogs.language-skills.title'"
  [confirmButtonText]="'i18n.common.save'"
  [disabled]="!languageSkillsForm.valid"
  (confirmEvent)="updateLanguageSkills($event)"
>
  <form [formGroup]="languageSkillsForm">
    <div formArrayName="languageSkills">
      <div class="tw-hidden tw-gap-30 tw-pb-18 d:tw-grid d:tw-grid-cols-15">
        <p class="msa-text tw-col-span-3 tw-text-black-light-5.5">
          {{ "i18n.person-data.communication.table.language-skills" | safeTranslate }}
        </p>
        <p class="msa-text tw-col-span-2 tw-text-center tw-text-black-light-5.5">
          {{ "i18n.person-data.communication.table.mother-tongue" | safeTranslate }}
        </p>
        <p class="msa-text tw-col-span-3 tw-text-black-light-5.5">
          {{ "i18n.person-data.communication.table.speaking" | safeTranslate }}
        </p>
        <p class="msa-text tw-col-span-3 tw-text-black-light-5.5">
          {{ "i18n.person-data.communication.table.reading" | safeTranslate }}
        </p>
        <p class="msa-text tw-col-span-3 tw-text-black-light-5.5">
          {{ "i18n.person-data.communication.table.writing" | safeTranslate }}
        </p>
      </div>
      <div class="tw-border-t tw-border-t-black-light-4">
        @for (
          languageSkill of languageSkillsForm.controls.languageSkills.controls;
          track languageSkill;
          let idx = $index
        ) {
          <div
            class="tw-grid tw-grid-cols-2 tw-content-center tw-gap-9 tw-border-b tw-border-b-black-light-4 tw-py-15 d:tw-grid-cols-15 d:tw-gap-30 d:tw-py-18"
            [formGroupName]="idx"
          >
            <div class="d:tw-col-span-3">
              <label class="msa-text tw-pb-6 d:tw-hidden" for="language-{{ idx }}">
                {{ "i18n.person-data.communication.table.language-skills" | safeTranslate }}
              </label>
              <msa-autocomplete
                id="language-{{ idx }}"
                [filterControl]="$any(languageSkill.get('language'))"
                [options]="languages()!"
              />
            </div>
            <div class="tw-flex tw-items-center d:tw-col-span-2 d:tw-justify-center d:tw-text-center">
              <msa-checkbox id="motherTongue-{{ idx }}" formControlName="motherTongue">
                <label class="msa-text d:tw-hidden" for="motherTongue-{{ idx }}">
                  {{ "i18n.person-data.communication.table.mother-tongue" | safeTranslate }}
                </label>
              </msa-checkbox>
            </div>
            <div class="d:tw-col-span-3">
              <label class="msa-text tw-pb-6 d:tw-hidden" for="skillLevelTalk-{{ idx }}">
                {{ "i18n.person-data.communication.table.speaking" | safeTranslate }}
              </label>
              <mat-form-field class="tw-w-full">
                <mat-select id="skillLevelTalk-{{ idx }}" formControlName="skillLevelTalk">
                  <mat-option [value]="LanguageSkillsDtoSkillLevelTalkEnum.High">
                    {{ "i18n.person-data.communication.table.value.high" | safeTranslate }}
                  </mat-option>
                  <mat-option [value]="LanguageSkillsDtoSkillLevelTalkEnum.Medium">
                    {{ "i18n.person-data.communication.table.value.medium" | safeTranslate }}
                  </mat-option>
                  <mat-option [value]="LanguageSkillsDtoSkillLevelTalkEnum.Low">
                    {{ "i18n.person-data.communication.table.value.low" | safeTranslate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d:tw-col-span-3">
              <label class="msa-text tw-pb-6 d:tw-hidden" for="skillLevelRead-{{ idx }}">
                {{ "i18n.person-data.communication.table.reading" | safeTranslate }}
              </label>
              <mat-form-field class="tw-w-full">
                <mat-select id="skillLevelRead-{{ idx }}" formControlName="skillLevelRead">
                  <mat-option [value]="LanguageSkillsDtoSkillLevelReadEnum.High">
                    {{ "i18n.person-data.communication.table.value.high" | safeTranslate }}
                  </mat-option>
                  <mat-option [value]="LanguageSkillsDtoSkillLevelReadEnum.Medium">
                    {{ "i18n.person-data.communication.table.value.medium" | safeTranslate }}
                  </mat-option>
                  <mat-option [value]="LanguageSkillsDtoSkillLevelReadEnum.Low">
                    {{ "i18n.person-data.communication.table.value.low" | safeTranslate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d:tw-col-span-3">
              <label class="msa-text tw-pb-6 d:tw-hidden" for="skillLevelWrite-{{ idx }}">
                {{ "i18n.person-data.communication.table.writing" | safeTranslate }}
              </label>
              <mat-form-field class="tw-w-full">
                <mat-select id="skillLevelWrite-{{ idx }}" formControlName="skillLevelWrite">
                  <mat-option [value]="LanguageSkillsDtoSkillLevelWriteEnum.High">
                    {{ "i18n.person-data.communication.table.value.high" | safeTranslate }}
                  </mat-option>
                  <mat-option [value]="LanguageSkillsDtoSkillLevelWriteEnum.Medium">
                    {{ "i18n.person-data.communication.table.value.medium" | safeTranslate }}
                  </mat-option>
                  <mat-option [value]="LanguageSkillsDtoSkillLevelWriteEnum.Low">
                    {{ "i18n.person-data.communication.table.value.low" | safeTranslate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="tw-col-span-2 tw-flex tw-h-[40px] tw-justify-end d:tw-col-span-1 d:tw-h-auto">
              <div class="tw-content-end d:tw-content-center">
                <button
                  class="tw-flex tw-content-center tw-text-black-light-5"
                  (click)="removeLanguageSkill(idx)"
                  data-cy="deleteLanguageSkill"
                >
                  <span class="msa-text d:tw-hidden">
                    {{ "i18n.person-data.dialogs.language-skills.delete" | safeTranslate }}
                  </span>
                  <mat-icon class="tw-ms-12 d:tw-mx-21">delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    <button
      class="tw-mt-15 d:tw-mt-18 d:!tw-w-auto"
      (click)="addLanguageSkill()"
      color="accent"
      data-cy="addLanguageSkill"
      mat-flat-button
    >
      <mat-icon>add</mat-icon>
      {{ "i18n.person-data.dialogs.language-skills.add-button" | safeTranslate }}
    </button>
  </form>
</msa-dialog>
