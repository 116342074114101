import {ChangeDetectionStrategy, Component, computed, DestroyRef, inject} from '@angular/core';
import {MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {tap} from 'rxjs';
import {AuthenticationService} from '@shared/core/services/authentication.service';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {AgreementService} from '@shared/shared-module/services/agreement/agreement.service';
import {DutyRestService, PersonRestService} from '../../core/api/generated/msa-person-data';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaDialogComponent, SafeTranslatePipe, MsaContentNoticeComponent],
  selector: 'msa-agreement-edit-dialog',
  standalone: true,
  templateUrl: './agreement-edit-dialog.component.html'
})
export class AgreementEditDialogComponent {
  private agreementService = inject(AgreementService);
  private authenticationService = inject(AuthenticationService);
  private destroyRef = inject(DestroyRef);
  private dutyRestService = inject(DutyRestService);
  private personRestService = inject(PersonRestService);

  requestStatus = toSignal(this.dutyRestService.getRequestStatus());
  dirtyStateStatus = toSignal(this.personRestService.getDirtyStateStatus());
  hasOpenRequests = computed(() => this.requestStatus()?.hasOpenRequests ?? true);
  hasEntitiesInDirtyState = computed(() => this.dirtyStateStatus()?.hasEntitiesInDirtyState ?? true);

  updateAgreement($event: any) {
    this.agreementService
      .updateAgreement(false)
      .pipe(tap(() => this.authenticationService.logout()))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: $event.resolve,
        error: $event.reject
      });
  }
}
