<msa-dialog
  [confirmButtonText]="'i18n.reject-agreement-dialog.reject'"
  [disabled]="hasOpenRequests() || hasEntitiesInDirtyState()"
  [title]="'i18n.profile-settings.consent.menu.edit-consent'"
  (confirmEvent)="updateAgreement($event)"
>
  <div class="tw-bt-30 d:tw-bt-60 tw-flex tw-flex-col tw-gap-y-15 d:tw-gap-y-30">
    <div class="msa-text tw-flex tw-flex-col tw-gap-y-12">
      <p>{{ "i18n.reject-agreement-dialog.paragraph-1" | safeTranslate }}</p>
      <p>{{ "i18n.reject-agreement-dialog.paragraph-2" | safeTranslate }}</p>
      <p>{{ "i18n.reject-agreement-dialog.paragraph-3" | safeTranslate }}</p>
    </div>
    @if (hasOpenRequests()) {
      <app-msa-content-notice type="Warning">
        <p class="tw-text-white">{{ "i18n.reject-agreement-dialog.hasRequestsInProgress" | safeTranslate }}</p>
      </app-msa-content-notice>
    }
    @if (hasEntitiesInDirtyState()) {
      <app-msa-content-notice type="Warning">
        <p class="tw-text-white">{{ "i18n.reject-agreement-dialog.hasChangesInDirtyState" | safeTranslate }}</p>
      </app-msa-content-notice>
    }
  </div>
</msa-dialog>
