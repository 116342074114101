@if (personalStatuses()) {
  <msa-card cardTitle="i18n.militaryCareer.personaStatus.title">
    <div class="msa-text">
      <div class="msa-text tw-grid tw-grid-cols-5 tw-gap-x-30 tw-pb-18 tw-text-black-light-5.5 max-d:tw-hidden">
        <div class="tw-col-span-1 tw-col-start-3">
          {{ "i18n.militaryCareer.personaStatus.active" | safeTranslate }}
        </div>
        <div class="tw-col-span-1">
          {{ "i18n.militaryCareer.personaStatus.from" | safeTranslate }}
        </div>
        <div class="tw-col-span-1">
          {{ "i18n.militaryCareer.personaStatus.till" | safeTranslate }}
        </div>
      </div>
      @for (personalStatus of personalStatuses(); track $index) {
        <div
          class="tw-grid tw-grid-cols-[24px_1fr] tw-items-center tw-gap-x-6 tw-border-t tw-border-black-light-4 tw-py-15 last:tw-border-b max-d:tw-gap-y-9 d:tw-grid-cols-5 d:tw-gap-x-30 d:tw-py-18 d:last:tw-border-b-0"
        >
          <div class="tw-order-2 tw-col-span-1 d:tw-order-1 d:tw-col-span-2">
            <strong>
              {{ personalStatus.personalStatus?.descriptionDto | translateObject }}
            </strong>
          </div>
          <div class="tw-order-1 tw-col-span-1 tw-flex d:tw-order-2">
            <mat-icon class="!tw-size-24" [svgIcon]="personalStatus.isActive ? 'check' : 'close'" />
          </div>
          <div class="tw-order-3 tw-col-span-1 max-d:tw-hidden">
            {{ personalStatus.startDate | date: GERMAN_SHORT_DATE_FORMAT }}
          </div>
          <div class="tw-order-4 tw-col-span-1 max-d:tw-hidden">
            {{ personalStatus.endDate | date: GERMAN_SHORT_DATE_FORMAT }}
          </div>
          <div class="tw-order-3 tw-col-span-1 tw-col-start-2 d:tw-hidden">
            {{ personalStatus.startDate | date: GERMAN_SHORT_DATE_FORMAT }}
            &nbsp;-&nbsp;
            {{ personalStatus.endDate | date: GERMAN_SHORT_DATE_FORMAT }}
          </div>
        </div>
      }
    </div>
  </msa-card>
}
