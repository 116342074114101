<msa-dialog
  [confirmButtonText]="'i18n.common.save'"
  [disabled]="emailForm.invalid"
  [footNote]="'i18n.person-data.dialogs.email-edit.foot-note'"
  [title]="title"
  (confirmEvent)="updateEmails($event)"
>
  <div class="msa-text tw-hidden tw-grid-cols-4 tw-text-black-light-5 d:tw-mt-45 d:tw-grid">
    <div class="tw-col-span-2 tw-col-start-2">
      {{ "i18n.person-data.dialogs.email-edit.column-email" | safeTranslate }}
    </div>
    <div class="tw-col-span-1">
      {{ "i18n.person-data.dialogs.email-edit.column-preferred" | safeTranslate }}
    </div>
  </div>

  <form class="msa-text max-d:tw-mt-15 [&>*]:tw-border-black-light-7" [formGroup]="emailForm">
    <div class="tw-grid tw-grid-cols-1 tw-items-center tw-gap-x-12 tw-border-t tw-py-15 d:tw-grid-cols-4 d:tw-py-18">
      <div class="tw-col-span-1 max-d:tw-mb-6">
        <label for="businessEmailInput">
          {{ "i18n.person-data.dialogs.email-edit.private" | safeTranslate }}
        </label>
      </div>
      <div class="tw-col-span-2">
        <msa-text-input id="businessEmailInput" formControlName="privateEmail">
          <ng-container errorContainer>
            @if (emailForm.controls.privateEmail.touched && emailForm.controls.privateEmail.errors) {
              <p class="msa-text-small tw-text-vermillion-light-2">
                {{ "i18n.person-data.dialogs.email-edit.error" | safeTranslate }}
              </p>
            }
          </ng-container>
        </msa-text-input>
      </div>
      <div class="tw-col-span-1 tw-mt-9 tw-flex tw-items-center tw-py-12 d:tw-justify-center">
        <app-msa-radiobutton id="privateEmailPreferred" value="privateEmailPreferred" formControlName="preferredEmail">
          <label class="d:tw-hidden" for="privateEmailPreferred">
            {{ "i18n.person-data.dialogs.email-edit.column-preferred" | safeTranslate }}
          </label>
        </app-msa-radiobutton>
      </div>
    </div>

    <div class="tw-grid tw-grid-cols-1 tw-items-center tw-gap-x-12 tw-border-t tw-py-15 d:tw-grid-cols-4 d:tw-py-18">
      <div class="tw-col-span-1 max-d:tw-mb-6">
        <label for="privateEmailInput">
          {{ "i18n.person-data.dialogs.email-edit.business" | safeTranslate }}
        </label>
      </div>
      <div class="tw-col-span-2">
        <msa-text-input id="privateEmailInput" formControlName="businessEmail">
          <ng-container errorContainer>
            @if (emailForm.controls.businessEmail.touched && emailForm.controls.businessEmail.errors) {
              <p class="msa-text-small tw-text-vermillion-light-2">
                {{ "i18n.person-data.dialogs.email-edit.error" | safeTranslate }}
              </p>
            }
          </ng-container>
        </msa-text-input>
      </div>
      <div class="tw-col-span-1 tw-mt-9 tw-flex tw-items-center tw-py-12 d:tw-justify-center">
        <app-msa-radiobutton
          id="businessEmailPreferred"
          value="businessEmailPreferred"
          formControlName="preferredEmail"
        >
          <label class="d:tw-hidden" for="businessEmailPreferred">
            {{ "i18n.person-data.dialogs.email-edit.column-preferred" | safeTranslate }}
          </label>
        </app-msa-radiobutton>
      </div>
    </div>

    <div
      class="tw-grid tw-grid-cols-1 tw-items-center tw-gap-x-12 tw-border-b tw-border-t tw-py-15 d:tw-grid-cols-4 d:tw-py-18"
    >
      <div class="tw-col-span-1 max-d:tw-mb-6">
        <label for="otherEmailInput">
          {{ "i18n.person-data.dialogs.email-edit.other" | safeTranslate }}
        </label>
      </div>
      <div class="tw-col-span-2">
        <msa-text-input id="otherEmailInput" formControlName="otherEmail">
          <ng-container errorContainer>
            @if (emailForm.controls.otherEmail.touched && emailForm.controls.otherEmail.errors) {
              <p class="msa-text-small tw-text-vermillion-light-2">
                {{ "i18n.person-data.dialogs.email-edit.error" | safeTranslate }}
              </p>
            }
          </ng-container>
        </msa-text-input>
      </div>
      <div class="tw-col-span-1 tw-mt-9 tw-flex tw-items-center tw-py-12 d:tw-justify-center">
        <app-msa-radiobutton id="otherEmailPreferred" value="otherEmailPreferred" formControlName="preferredEmail">
          <label class="d:tw-hidden" for="otherEmailPreferred">
            {{ "i18n.person-data.dialogs.email-edit.column-preferred" | safeTranslate }}
          </label>
        </app-msa-radiobutton>
      </div>
    </div>
  </form>
</msa-dialog>
