import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {MenuItem, MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {EmergencyContactsEditDialogComponent} from '../../dialogs/emergency-contacts-edit-dialog/emergency-contacts-edit-dialog.component';
import {NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MatIconModule} from '@angular/material/icon';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaCardComponent, SafeTranslatePipe, MatIconModule, TranslateObjectPipe, NgxMaskPipe],
  providers: [provideNgxMask()],
  selector: 'msa-emergency-contacts',
  standalone: true,
  templateUrl: './emergency-contacts.component.html'
})
export class EmergencyContactsComponent {
  menuItems: Array<MenuItem> = [
    {
      component: EmergencyContactsEditDialogComponent,
      menuItemTitle: 'i18n.person-data.dialogs.emergency-contacts.title'
    }
  ];

  emergencyContacts = readStoreSignal(PersonDataStateSelectors.getEmergencyContacts);
}
