<msa-card [cardTitle]="titleText()" [noPadding]="true">
  <div class="msa-text tw-grid tw-grid-cols-[min-content_auto] d:tw-grid-rows-[min-content_1fr]">
    <div class="d:tw-row-span-2">
      <div
        class="tw-grid tw-h-[116px] tw-w-[116px] tw-place-content-center tw-bg-black-light-8 d:tw-h-[218px] d:tw-w-[218px]"
      >
        <mat-icon
          class="!tw-h-[70px] !tw-w-auto tw-text-[#213344] d:!tw-h-[132px]"
          [svgIcon]="
            'avatar_' + (superiorContact().gender === PersonMilitarySuperiorDataDtoGenderEnum.F ? 'female' : 'male')
          "
        />
      </div>
    </div>
    <p class="tw-mb-18 max-d:tw-px-18 max-d:tw-py-24 max-d:tw-pt-15 d:tw-row-span-1 d:tw-px-30 d:tw-pt-24">
      <strong>
        {{ superiorContact().rank?.shortDescriptionDto | translateObject }}
        @if (superiorContact().rankAddition === RankAddition.J) {
          {{ "i18n.user.banner.rank-addition.j" | safeTranslate }}
        }
        {{ superiorContact().firstName }}
        {{ superiorContact().lastName }}
      </strong>
    </p>
    <div class="tw-px-18 max-d:tw-col-span-2 max-d:tw-py-24 d:tw-row-span-1 d:tw-px-30 d:tw-pb-24">
      <dl>
        @if (superiorContact().preferredPhoneNumber) {
          <div
            class="tw-grid tw-grid-cols-1 tw-border-black-light-3 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-mb-12 d:tw-grid-cols-5 d:tw-gap-x-30"
          >
            <dt class="msa-text tw-col-span-1 tw-text-black-light-5.5 d:tw-col-span-2">
              {{ "i18n.contacts.superior-contact.phone-number" | safeTranslate }}
            </dt>
            <dd class="msa-text tw-col-span-4 tw-flex tw-items-center d:tw-col-span-3">
              {{ superiorContact().preferredPhoneNumber! | mask: "+AA AA AAA AA AA" }}
            </dd>
          </div>
        }
        @if (superiorContact().preferredEmailAddress) {
          <div class="tw-grid tw-grid-cols-1 max-d:tw-gap-y-9 d:tw-grid-cols-5 d:tw-gap-x-30">
            <dt class="msa-text tw-col-span-1 tw-text-black-light-5.5 d:tw-col-span-2">
              {{ "i18n.contacts.superior-contact.e-mail" | safeTranslate }}
            </dt>
            <dd class="msa-text tw-col-span-4 tw-flex tw-items-center d:tw-col-span-3">
              {{ superiorContact().preferredEmailAddress }}
            </dd>
          </div>
        }
      </dl>
    </div>
  </div>
</msa-card>
