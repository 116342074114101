import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {
  DialogConfirmEvent,
  MsaDialogAction,
  MsaDialogComponent
} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {UpdatePhones} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {AppStateSelectors} from '@shared/shared-module/stores/selectors/app.state.selectors';
import {
  CommunicationPhonesByType,
  PersonDataStateSelectors
} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {phoneValidator} from '@shared/shared-module/validators/phone.validator';
import {catchError, EMPTY, filter, map, Observable, of, switchMap, tap} from 'rxjs';
import {
  Language,
  PersonRestService,
  PhoneType,
  PhoneUpdateDto,
  PhoneVerifyDto,
  VerifyResponseDto
} from '../../core/api/generated/msa-person-data';
import {PhoneVerifyDialogComponent} from '../phone-verify-dialog/phone-verify-dialog.component';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MsaTextInputComponent} from '@shared/shared-module/components/msa-text-input/msa-text-input.component';
import {MsaRadiobuttonComponent} from '@shared/shared-module/components/msa-radiobutton/msa-radiobutton.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaDialogComponent, SafeTranslatePipe, ReactiveFormsModule, MsaTextInputComponent, MsaRadiobuttonComponent],
  selector: 'msa-phone-edit-dialog',
  standalone: true,
  templateUrl: './phone-edit-dialog.component.html'
})
export class PhoneEditDialogComponent implements OnInit {
  private store: Store = inject(Store);
  private personRestService: PersonRestService = inject(PersonRestService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private matDialog: MatDialog = inject(MatDialog);

  title: TranslationString = 'i18n.person-data.dialogs.phone-edit.title';
  example: TranslationString = 'i18n.person-data.dialogs.phone-edit.example';
  language: Language = Language.De;
  phoneMask: string = '+00 00 000 00 00*';

  phoneForm = new FormGroup({
    privateCellPhone: new FormControl('', [phoneValidator()]),
    businessCellPhone: new FormControl('', [phoneValidator()]),
    privatePhone: new FormControl('', [phoneValidator()]),
    businessPhone: new FormControl('', [phoneValidator()]),
    preferredPhone: new FormControl('', Validators.required)
  });

  ngOnInit() {
    this.store
      .select(PersonDataStateSelectors.getCommunicationPhonesByType)
      .pipe(
        tap((phones: CommunicationPhonesByType) => this.populatePhoneFields(phones)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
    this.store
      .select(AppStateSelectors.slices.language)
      .pipe(
        tap((language: Language) => {
          this.language = language;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
    this.phoneForm.controls.preferredPhone.valueChanges
      .pipe(
        filter(isDefined),
        tap((preferredEmail: string) => this.updateValidatorsOnPreferredChange(preferredEmail)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  populatePhoneFields(phones: CommunicationPhonesByType) {
    this.phoneForm.patchValue({
      privateCellPhone: phones.CELL.number,
      businessCellPhone: phones.CELP.number,
      privatePhone: phones.HOME.number,
      businessPhone: phones.BUSN.number,
      preferredPhone: phones.CELL.preferred
        ? 'privateCellPhonePreferred'
        : phones.CELP.preferred
          ? 'businessCellPhonePreferred'
          : undefined
    });
  }

  updatePhones($event: DialogConfirmEvent) {
    const privateCellPhoneDto: PhoneUpdateDto = {
      type: PhoneType.Cell,
      number: this.phoneForm.value.privateCellPhone ?? '',
      preferred: this.phoneForm.value.preferredPhone === 'privateCellPhonePreferred'
    };
    const businessCellPhoneDto: PhoneUpdateDto = {
      type: PhoneType.Celp,
      number: this.phoneForm.value.businessCellPhone ?? '',
      preferred: this.phoneForm.value.preferredPhone === 'businessCellPhonePreferred'
    };
    const privatePhoneDto: PhoneUpdateDto = {
      type: PhoneType.Home,
      number: this.phoneForm.value.privatePhone ?? ''
    };
    const businessPhoneDto: PhoneUpdateDto = {
      type: PhoneType.Busn,
      number: this.phoneForm.value.businessPhone ?? ''
    };
    let phones: Array<PhoneUpdateDto> = [privateCellPhoneDto, businessCellPhoneDto, privatePhoneDto, businessPhoneDto];
    // Filter out empty phones
    phones = phones.filter(phone => !(!phone.number || phone.number.length < 1));

    this.validatePreferredPhone(phones)
      .pipe(
        tap(isValidated => {
          if (isValidated) {
            $event.resolve();
            return true;
          } else {
            $event.reject();
            return EMPTY;
          }
        }),
        catchError(() => {
          $event.reject();
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  validatePreferredPhone(phones: PhoneUpdateDto[]): Observable<boolean> {
    // Check if preferred phone is set
    const phoneToValidate = phones.find(x => x.preferred === true);
    if (!phoneToValidate) {
      return of(false);
    }

    const phoneVerifyDto: PhoneVerifyDto = {
      number: phoneToValidate.number ?? '',
      language: this.language ?? Language.De
    };

    // Check if the following phone is already validated
    return this.personRestService.verifyPersonPhone(phoneVerifyDto).pipe(
      switchMap((phoneVerifyResponseDto: VerifyResponseDto) => {
        // If the mail is not yet validated open a verification Dialog
        if (phoneVerifyResponseDto.alreadyVerified === false) {
          if (phoneVerifyResponseDto.verificationCode === undefined) {
            throw new Error(`Undefined Verification Code!`);
          } else {
            return this.openDialog(phoneVerifyDto, phoneVerifyResponseDto.verificationCode).pipe(
              map(code => {
                if (code === '') {
                  return false;
                }
                phoneToValidate.verificationCode = code;
                phones = phones.map(phone =>
                  phone.number === phoneToValidate.number && phone.preferred === true ? phoneToValidate : phone
                );
                return this.store.dispatch(new UpdatePhones(phones));
              })
            );
          }

          // If the mail is already validated return true
        } else {
          phoneToValidate.verificationCode = phoneVerifyResponseDto.verificationCode;
          phones = phones.map(phone =>
            phone.number === phoneToValidate.number && phone.preferred === true ? phoneToValidate : phone
          );
          return this.store.dispatch(new UpdatePhones(phones));
        }
      })
    );
  }

  openDialog(phoneVerifyDto: PhoneVerifyDto, verificationHash: string): Observable<string> {
    const verifyDialog = this.matDialog.open(PhoneVerifyDialogComponent, {
      data: {phoneVerifyDto: phoneVerifyDto, verificationHash: verificationHash}
    });
    return verifyDialog.beforeClosed().pipe(
      // Return if Verification was successful or cancelled
      map(result => {
        if (result === MsaDialogAction.CONFIRM) {
          return verifyDialog.componentInstance.phoneVerifyForm.value.verificationCode ?? '';
        } else {
          return '';
        }
      })
    );
  }

  updateValidatorsOnPreferredChange(preferredPhone: string) {
    const controls = this.phoneForm.controls;
    controls.privateCellPhone.setValidators([phoneValidator()]);
    controls.businessCellPhone.setValidators([phoneValidator()]);
    if (preferredPhone === 'privateCellPhonePreferred') {
      controls.privateCellPhone.setValidators([Validators.required, phoneValidator()]);
    } else if (preferredPhone === 'businessCellPhonePreferred') {
      controls.businessCellPhone.setValidators([Validators.required, phoneValidator()]);
    }
    controls.privateCellPhone.updateValueAndValidity();
    controls.businessCellPhone.updateValueAndValidity();
  }
}
